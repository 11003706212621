/**
 * Enum for shipping label referance.
 * @readonly
 * @enum {{key: string, value: string , text: string}}
 */
const shippingLabelReferanceEnum = Object.freeze({
  FBA: { key: 'FBA', value: 'FBA', text: 'FBA' },
  PROBLEM: { key: 'PROBLEM', value: 'PROBLEM', text: 'Problem Management' },
})

export default shippingLabelReferanceEnum
