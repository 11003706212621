<template>
  <div>
    <div v-if="carrier">
      <span class="text-warning">
        {{ carrier }}
      </span>
    </div>
    <div v-if="tracking">
      <small class="text-muted  d-block">
        Tracking #
      </small>
      <span>
        <copy-button :copy-value="tracking" class="m-0" />
        {{ tracking }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    carrier: {
      type: String,
      default: '',
    },
    tracking: {
      type: String,
      default: '',
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>

</style>
