import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get all problems
  async fetchAll(pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.problemManagement.Controller.findAll(pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get problem by id
  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.problemManagement.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get all problems by type
  async fetchAllByType(type) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.problemManagement.Controller.findAllByType(type))
        .then(response => {
          resolve({
            data: response.data.body.content,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get all problems by type
  async fetchAllByQuery(filters, params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.problemManagement.Controller.findAllByQuery(params), filters)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get all problems by status
  async fetchAllByStatus(status) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.problemManagement.Controller.findAllByStatus(status))
        .then(response => {
          resolve({
            data: response.data.body.content,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get all problems by resolution type
  async fetchAllByResolutionType(resType) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.problemManagement.Controller.findAllByProblemResolutionType(resType))
        .then(response => {
          resolve({
            data: response.data.body.content,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get all problems by company id
  async fetchAllByCompanyId(companyId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.problemManagement.Controller.findAllByCompanyId(companyId))
        .then(response => {
          resolve({
            data: response.data.body.content,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get all problems by company id, type and status
  async fetchAllByCompanyIdAndParams(companyId, type, status) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.problemManagement.Controller.findAllByCompanyIdAndParams(companyId, type, status))
        .then(response => {
          resolve({
            data: response.data.body.content,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // create problem
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.problemManagement.Controller.create(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // assign location
  async assignLocation(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.problemManagement.Controller.assignLocation(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update problem
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.problemManagement.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // take action
  async takeAction(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.problemManagement.Controller.takeAction(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete problem
  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.problemManagement.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async createMissingItemProblem(data) {
    const result = await axios.post(endpoints.problemManagement.Controller.createMissingItemProblem(), data)
    return result.data.body
  },

  async getListToDownload(data, pageable) {
    const result = await axios.post(endpoints.problemManagement.Controller.getListToDownload(pageable), data)
    return result.data
  },
}
