<template>
  <div>
    <div class="mt-half">
      <span class="text-muted">
        Type:
      </span>
      <span>
        {{ getEnumFormatted($enums.ProblemTypeEnum,problemType) }}
      </span>
      <span v-if="damageTypeExits">
        / {{ getEnumFormatted($enums.DamageTypeEnum, damageType) }}
      </span>
    </div>
    <div class="mt-half">
      <span class="text-muted">Status</span>
      <span :class="resolveProblemStatus(problemStatus)">
        {{ getEnumFormatted($enums.ProblemStatusEnum,problemStatus) }}
      </span>
    </div>
    <div class="mt-half">
      <span class="text-muted">
        Resolution Status:
      </span>
      <span :class="resolveResolutionStatus(problemResolutionType)">
        {{ getEnumFormatted($enums.ProblemResolutionTypeEnum, problemResolutionType) }}
      </span>
    </div>
  </div>
</template>

<script>
import { ValueToEnum } from '@robustshell/utils/index'

export default {
  props: {
    problemStatus: {
      type: String,
      default: '',
    },
    problemType: {
      type: String,
      default: '',
    },
    damageType: {
      type: String,
      default: '',
    },
    problemResolutionType: {
      type: String,
      default: '',
    },
  },
  computed: {
    damageTypeExits() {
      return this.damageType !== '' && this.damageType !== null && this.damageType !== undefined && this.damageType !== 'none'
    },
  },
  methods: {
    getEnumFormatted(items, val) {
      const enumItem = ValueToEnum(items, val)
      return enumItem.text
    },
    resolveProblemStatus(status) {
      if (status === 'open') return 'badge badge-light-danger'
      if (status === 'closed_with_resolution') return 'badge badge-light-success'
      if (status === 'no_action_taken_closed') return 'badge badge-light-secondary'
      if (status === 'waiting_action_from_prep_center') return 'badge badge-light-warning'
      return 'badge badge-light-info'
    },
    resolveResolutionStatus(status) {
      if (status === 'not_resolved_yet') return 'badge badge-light-danger'
      if (status === 'resolved') return 'badge badge-light-success'
      return 'badge badge-light-warning'
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
