<template>
  <div class="w-100 text-left">
    <div class="text-warning font-weight-bold">
      <p-icon name="bi-shop" color="gray" />
      {{ storeName }}
    </div>
    <div class="mt-half">
      <p-icon name="bi-building" color="gray" />
      {{ companyName }}
    </div>
    <div class="d-flex justify-content-between mt-half">
      <div v-if="hasPermission">
        <small class="d-block text-muted">Account#</small>
        <span role="button" class="text-info font-weight-bold" @click="triggerAccountNo">{{ accountNo }}</span>
      </div>
      <div>
        <small class="d-block text-muted">Suite#</small>
        <span>{{ suiteNo }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    accountNo: {
      type: [String, Number],
      default: '',
    },
    suiteNo: {
      type: [String, Number],
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
    storeName: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  methods: {
    triggerAccountNo() {
      this.$emit('account-no-clicked')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
