<template>
  <dx-util-popup
    ref="printerPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    :width="480"
    :height="360"
    @shown="printerPopupShown"
    @hidden="printerPopupHidden"
  >
    <dx-util-position at="center" my="center" />
    <dx-util-toolbar-item toolbar="bottom" location="after" template="cancelPopupButton" />
    <template #cancelPopupButton>
      <dx-util-button id="printerPopupCancelButton" text="Cancel" type="danger" @click="onCancel()" />
    </template>
    <dx-util-toolbar-item toolbar="bottom" location="after" template="submitPopupButton" />
    <template #submitPopupButton>
      <dx-util-button id="printerPopupSubmitButton" text="Save" type="success" @click="onSubmit()" />
    </template>
    <div class="form-group">
      <label for="printer-select-box">Printer</label>
      <dx-util-select-box
          id="printer-select-box"
          v-model="selectedPrinter"
          :data-source="printers"
          display-expr="Name"
        />
    </div>
    <div class="form-group">
      <label for="printer-select-box">Copy Count</label>
      <dx-util-number-box v-model="copies" :min="1" :max="100" />
    </div>
  </dx-util-popup>
</template>
<script>
import usePrinter from '@/libs/printer/print'
import { LabelParam } from '@/http/models/printer/entity/labelparam'
import printerVarabiles, { labelTypes } from '@/libs/printer/printer-varaibles'
import { formatDate } from '@core/utils/filter'

export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const {
      selectedPrinter,
      printers,
      truncateTitle,
      printStaticLabel,
    } = usePrinter()
    return {
      selectedPrinter,
      printers,
      truncateTitle,
      printStaticLabel,
    }
  },
  data() {
    return {
      popupTitle: '',
      selectedLabel: {},
      copies: 1,
    }
  },
  computed: {
    printerPopupIns() {
      return this.$refs.printerPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.createPrinterPopup()
        }
      },
    },
  },
  methods: {
    createPrinterPopup() {
      this.$nextTick(() => {
        this.printerPopupIns.show()
      })
    },
    printerPopupShown() {
      this.popupTitle = 'Print'
    },
    async onSubmit() {
      const {
        barcode, title, expressionDate,
      } = this.payload

      let expDate = ''
      if (expressionDate) {
        expDate = formatDate(expressionDate)
      }

      const params = []
      params.push(new LabelParam(printerVarabiles.BARCODE.value, barcode))
      params.push(new LabelParam(printerVarabiles.EXPRESSION_DATE.value, expDate))
      const titleStr = this.truncateTitle(title)
      const titleArr = titleStr.split(/(.{50})/).filter(p => p)
      if (titleArr.length > 1) {
        params.push(new LabelParam(printerVarabiles.TITLE_LINE1.value, titleArr[0]))
        params.push(new LabelParam(printerVarabiles.TITLE_LINE2.value, titleArr[1]))
      } else {
        params.push(new LabelParam(printerVarabiles.TITLE_LINE1.value, titleArr[0]))
        params.push(new LabelParam(printerVarabiles.TITLE_LINE2.value, ''))
      }

      await this.printStaticLabel(this.selectedPrinter.VendorName, this.selectedPrinter.Address, labelTypes.FNSKU.value, params)
      this.printerPopupIns.hide()
    },
    onCancel() {
      this.printerPopupIns.hide()
    },
    printerPopupHidden() {
      this.selectedPrinter = {}
    },
  },
}
</script>
