<template>
  <div>
    <div v-if="showBuyLabel" class="card card-psh border mb-1">
      <div class="bg-light-primary rounded-top p-1">
        <div class="psh-header mb-0 d-flex align-items-center">
          <div class="border-0">
            <p-icon name="psh-item-tracking3" color="warning" size="48px" class="mr-2" />
          </div>
          <div class="d-flex flex-column">
            <h4 class="card-title mb-25">
              PSH Shipping Label
            </h4>
            <p class="card-text mb-0">
              If you prefer, you can also get shipping label from PSH.
            </p>
          </div>
          <div class="ml-auto">
            <dx-util-button
              text="Buy Label"
              type="default"
              styling-mode="contained"
              @click="onClickBuyShippingLabel"
            />
          </div>
        </div>
      </div>
      <div v-if="pshShippingLabels.length > 0" class="card-body">
        <table class="table table-sm table-bordered rounded">
          <tr v-for="item in pshShippingLabels" :key="item.id">
            <td>{{ item.carrier }}</td>
            <td class="w-100">
              {{ item.trackingNumber }}</td>
            <td>
              <a :href="item.labelUrl" target="_blank" class="btn btn-sm btn-primary" rel="noopener noreferrer">
                <p-icon name="feather-download-cloud" size="16px" class="align-text-center" />
            </a></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="card border h-100">
      <div class="d-flex card-header bg-light-primary p-1">
        <div class="px-half">
          <p-icon name="bi-clock-history" size="40px" color="primary" />
        </div>
        <div class="card-title ml-1 p-0">
          <h4 class="m-0">
            Problem History
            </h4>
        </div>
        <div class="flex-grow-1"></div>
        <div class="d-flex flex-column text-center mr-1">
          <span class="text-muted text-nowrap">Problem Type</span>
          <span class="badge badge-danger my-half px-1 py-half">{{ detail.history.problem.problemType }}</span>
        </div>
        <div class="d-flex flex-column text-center mr-1">
          <span class="text-muted text-nowrap">Damage Type</span>
          <span class="badge badge-danger my-half px-1 py-half">{{ detail.history.problem.damageType }}</span>
        </div>
        <div class="d-flex flex-column text-center mr-1">
          <span class="text-muted text-nowrap">Action</span>
          <span class="badge badge-success my-half px-1 py-half">{{ detail.history.problem.resolutionType }}</span>
        </div>
        <div class="d-flex flex-column text-center mr-1">
          <span class="text-muted text-nowrap">Status</span>
          <span class="badge badge-warning my-half px-1 py-half">{{ detail.history.problem.status }}</span>
        </div>
      </div>
      <div class="card-body">
        <div class="px-half py-1 mb-3 border-bottom">
          <p class="m-0" style="font-size:14px;">
            <span class="text-warning">Opened by </span>{{ detail.openedBy }}
            <span class="text-warning">on </span>{{ formatedDate(detail.history.problem.date) }}
          </p>
          <span v-if="detail.history.problem.notes" class="text-warning">Notes: </span> {{ detail.history.problem.notes }}
        </div>
        <div v-if="detail.history" class="d-flex mt-1">
          <dx-util-scroll-view height="600px" width="100vw">
            <app-timeline>
              <app-timeline-item v-for="(action, index) in detail.history.actions" :key="action.date + index"
                :fill-border="true"
                variant="info"
                :icon="getHistoryStyle(action.resolutionType).icon"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5 class="text-darken font-weight-bolder">
                    Action:
                    <span class="badge badge-success mr-1">
                      {{ getEnumFormated(action.resolutionType) }}
                    </span>
                  </h5>
                  <slot v-if="action.resolutionType === ProblemResolutionTypeEnum.resolved.value" />
                  <div class="flex-grow-1" />
                  <small class="mr-1">{{ formatedDate(action.date) }}</small>
                </div>
                <div>
                <p> Notes: {{ action.notes }} </p>
                <h6>Action Taken By: {{ action.actionTakenBy }}</h6>
                </div>
              </app-timeline-item>
            </app-timeline>
          </dx-util-scroll-view>
          <!--/ timeline -->
        </div>
      </div>
    </div>
    <label-form
      v-if="showBuyLabel"
      :component-id="labelFormComponentId"
      :reference-id="referenceId"
      :reference-key="referenceKey"
      @on-label-form-closed="onEmitCloseLabelForm"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import shipmentLabelService from '@/http/requests/external/shipmentLabels.js'
import { formatDate } from '@core/utils/filter'
import { ValueToEnum } from '@robustshell/utils/index'
import { v4 as uuidv4 } from 'uuid'
import find from 'lodash/find'
import LabelForm from '@/views/apps/tools/labels/LabelForm.vue'
import useCurrentUser from '@/libs/app/current-user'
import shippingLabelReferanceEnum from '@/enums/shipping-label-referance.enum.js'
import { ProblemResolutionTypeEnum } from '@/enums'
import historyConsts from './problemHistoryConsts'

export default {
  components: {
    AppTimeline,
    LabelForm,
    AppTimelineItem,
  },
  props: {
    problemId: {
      type: Number,
      default: 0,
    },
    detail: {
      type: Object,
      default: () => ({
        history: {
          problem: {},
          actions: [],
        },
      }),
    },
  },
  setup() {
    const {
      userCompanyType,
    } = useCurrentUser()

    return {
      userCompanyType,
    }
  },
  data() {
    return {
      labelFormComponentId: '',
      pshShippingLabels: [],
      referenceId: '',
      referenceKey: shippingLabelReferanceEnum.PROBLEM.value,
      ProblemResolutionTypeEnum,
    }
  },
  computed: {
    showBuyLabel() {
      return this.userCompanyType === 'warehouse'
    },
  },
  watch: {
    problemId: {
      handler(val) {
        this.referenceId = val.toString()
        if (this.showBuyLabel) {
          this.getShippingLabelByReference(val)
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatedDate(date) {
      return formatDate(date)
    },
    getEnumFormated(val) {
      const enumItem = ValueToEnum(this.$enums.ProblemResolutionTypeEnum, val, 'value')
      this.getHistoryStyle(val)
      return enumItem.text
    },
    getHistoryStyle(val) {
      const styles = find(historyConsts.getProblemHistoryConsts(), e => e.key === val)
      return styles
    },

    async getShippingLabelByReference(id) {
      const parsed = parseInt(id, 10)
      const result = await shipmentLabelService.getShippingLabelByReference(shippingLabelReferanceEnum.PROBLEM.value, parsed)
      const { data } = result
      this.pshShippingLabels.length = 0
      this.pshShippingLabels = [...data]
    },
    onClickBuyShippingLabel() {
      this.labelFormComponentId = uuidv4()
    },
    async onEmitCloseLabelForm() {
      const parsed = parseInt(this.shipmentBatchId, 10)
      const result = await shipmentLabelService.getShippingLabelByReference(shippingLabelReferanceEnum.PROBLEM.value, parsed)
      const { data } = result
      this.pshShippingLabels.length = 0
      this.pshShippingLabels = [...data]
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
