<template>
  <div>
    <div v-if="!isEditable">
      <div v-if="checkWarehouse(row.value)" class="text-center">
        <span>
          {{ row.value }}
        </span>
        <p-icon role="button" name="bi-pencil-square" class="text-primary ml-1" @click.native="isEditable = true" />
      </div>
      <div v-else>
        <dx-util-button text="Assign" :element-attr="assignBtnOptions" type="success" hint="Assign new location" @click="isEditable = true" />
      </div>
    </div>
    <div v-else>
      <dx-util-text-box
      :ref="`textbox-location-${row.data.id}`"
      v-model.trim="locationData.location"
      width="120"
      :min="0"
      @content-ready="setEditable"
      @enter-key="handleSubmit"
      @key-down="onKeyDown"
    >
      <dx-text-box-button
        :name="`location-${row.data.id}`"
        :options="{
          icon: 'save',
          type: 'success',
          elementAttr: { id: `location-${row.data.id}` },
          onClick: (e) => handleSubmit(e)
          }"
        location="after"
      />
  </dx-util-text-box>
    </div>
  </div>
</template>

<script>
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import problemManagementService from '@/http/requests/problem-management/problemManagementService'
import { Notify } from '@robustshell/utils/index'

export default {
  components: {
    'dx-text-box-button': DxTextBoxButton,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditable: false,
      assignBtnOptions: {
        class: 'btn-assign',
      },
      locationData: {
        id: null,
        location: '',
      },
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  mounted() {
    this.locationData.location = this.row.value
  },
  methods: {
    setEditable(e) {
      e.component.focus()
    },
    checkWarehouse(e) {
      const val = e
      return (val && val !== '' && typeof val !== 'undefined' && e !== null)
    },
    async handleSubmit(e) {
      if (!this.checkWarehouse(this.locationData.location)) {
        Notify.warning('Please enter a valid warehouse location.')
        return
      }
      this.locationData.id = this.row.data.id
      await problemManagementService.assignLocation(this.locationData)
      this.$emit('emit-location-updated')
      this.isEditable = true
      this.resetForm()
    },
    onKeyDown(e) {
      const escapeKey = 27
      if (this.isEditable && e.event.which === escapeKey) {
        this.isEditable = false
        this.resetForm()
      }
    },
    resetForm() {
      this.locationData.location = ''
      this.locationData.id = null
    },
  },

}
</script>

<style lang="scss" scoped>
  .btn-assign{
    color: #fff !important;
    width: 100% !important;
    .dx-button-content {
      padding: 4px 4px 4px !important;
    }
  }

</style>
