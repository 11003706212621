const problemHistoryConsts = [
  {
    key: 'none',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'LogInIcon',
  },
  {
    key: 'not_resolved_yet',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'ClockIcon',
  },
  {
    key: 'dispose',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'LogOutIcon',
  },
  {
    key: 'donate',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'GiftIcon',
  },
  {
    key: 'return_to_customer',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'RotateCcwIcon',
  },
  {
    key: 'return_to_supplier',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'RefreshCcwIcon',
  },
  {
    key: 'investigate',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'SearchIcon',
  },
  {
    key: 'reimburse',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'DollarSignIcon',
  },
  {
    key: 'changed_item_action',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'GitPullRequestIcon',
  },
  {
    key: 'use_alternative_asin',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'SlidersIcon',
  },
  {
    key: 'data_entered',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'SlidersIcon',
  },
  {
    key: 'restock_to_inbound',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'LogInIcon',
  },
  {
    key: 'resolved',
    color: '#2c3e50',
    bgColor: 'rgba(236, 240, 241,0.4)',
    icon: 'CheckSquareIcon',
  },
]
export default {
  getProblemHistoryConsts() {
    return problemHistoryConsts
  },
}
