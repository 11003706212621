import BaseSearchFilter from './BaseSearchFilter'

class ProblemFilter extends BaseSearchFilter {
  /** @type {string} */
  #problemType = 'none'

  #damageType = 'none'

  #problemStatus = 'none'

  #resolutionType = 'none'

  constructor() {
    super()
    this.#problemType = this.problemType
    this.#damageType = this.damageType
    this.#problemStatus = this.problemStatus
    this.#resolutionType = this.resolutionType
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.problemType = 'none'
    this.damageType = 'none'
    this.problemStatus = 'none'
    this.resolutionType = 'none'
  }

  /**
   * The complete ProblemFilter get filters.
   * @typedef {Object} filters
   * @property {number} accountNo - Account Number.
   * @property {number} companyId - Company Id.
   * @property {number} storeId - Store Id.
   * @property {number} warehouseId - Store Id.
   * @property {any} beginDate - Begin date.
   * @property {any} endDate - End date.
   * @property {string} tenantType - Tenant Type.
   * @property {string} q - Query.
   * @property {string} problemType - Problem Type.
   * @property {string} damageType - Damage Type.
   * @property {string} problemStatus - Problem Status.
   * @property {string} resolutionType - Resolution Type.
   */

  /** @returns {filters} {@link filters} objects */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      problemType: this.problemType,
      damageType: this.damageType,
      problemStatus: this.problemStatus,
      resolutionType: this.resolutionType,
      q: this.q,
    }
    return filter
  }

  /** @returns {string} */
  get problemType() {
    return this.#problemType
  }

  /** @param {string} value */
  set problemType(value) {
    this.#problemType = value
  }

  /** @returns {string} */
  get damageType() {
    return this.#damageType
  }

  /** @param {string} value */
  set damageType(value) {
    this.#damageType = value
  }

  /** @returns {string} */
  get problemStatus() {
    return this.#problemStatus
  }

  /** @param {string} value */
  set problemStatus(value) {
    this.#problemStatus = value
  }

  /** @returns {string} */
  get resolutionType() {
    return this.#resolutionType
  }

  /** @param {string} value */
  set resolutionType(value) {
    this.#resolutionType = value
  }
}

export default new ProblemFilter()
