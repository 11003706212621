<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="problemManagementGrid"
          ref="problemManagementGridRef"
          height="calc(100vh - 150px)"
          :data-source="problemManagementSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
          @exporting="onExporting"
          @editor-preparing="onEditorPreparing"
        >
          <dx-column-chooser
            :enabled="true"
            mode="select"
            title="Column Chooser"
          />
          <dx-search-panel
            :visible="true"
            :width="200"
            placeholder="Search..."
          />
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="page"
            :allow-select-all="true"
            :width="10"
            mode="multiple"
          />
          <template #buttons="{ data }">
            <div>
              <div v-for="item in data" :key="item.id" class="">
                <dx-util-button :text="item.name" :icon="item.icon" class=" w-100 text-left" styling-mode="text" @click="setActionByItem(item)" />
              </div>
            </div>
          </template>
          <dx-column v-if="hasPermission" data-field="barcodes" cell-template="barcodesTemplate" width="190" :allow-sorting="false" />
          <template #barcodesTemplate="{data}">
            <div class="row px-1">
              <dx-util-button
                v-if="hasPermission && data.data.id"
                class="mt-half col-12"
                type="default"
                icon="bi bi-printer-fill"
                text="Print Barcode"
                @click="printOrderNumberBarcode(data.data.id)"
              />
              <dx-util-button
                v-if="hasPermission && data.data.orderNumber"
                class="mt-half col-12"
                type="success"
                icon="bi bi-printer-fill"
                text="Print Order No"
                @click="printOrderNumberBarcode(data.data.orderNumber, 'Order')"
              />
            </div>
          </template>
          <dx-column caption="Actions" alignment="center" cell-template="itemActions" :width="135" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                ref="btnTakeAction"
                type="warning" icon="chevrondoubleright"
                class="mr-half"
                hint="Take Action"
                @click="onTakeAction(data)"
              />
              <dx-util-button
                ref="btnAddCharge"
                hint="Add/Update Charges"
                type="success" icon="bi bi-coin"
                :visible="hasPermission"
                :disabled="isActiveChargeAction(data)"
                @click="onClickAddCharges(data)"
              />
            </div>
          </template>
          <dx-column data-field="title" caption="Item" cell-template="itemTemplate" :allow-sorting="false" />
          <template #itemTemplate="{data}">
            <problem-item-info
              :id="data.data.id"
              :title="data.data.title"
              :asin="data.data.asin"
              :upc="data.data.upc"
              :msku="data.data.msku"
              :small-image="data.data.largeImage"
              :large-image="data.data.largeImage"
              :supplier="data.data.supplier"
              :order-id="data.data.orderNumber"
              :is-truncated="false"
            >
            </problem-item-info>
          </template>
          <dx-column v-if="hasPermission" data-field="accountNo" caption="Store" alignment="left" cell-template="mainInfoTemplate" :allow-sorting="false" />
          <template #mainInfoTemplate="{ data }">
            <store-info
             :account-no="data.data.accountNo"
             :suite-no="data.data.suiteNo"
             :company-name="data.data.companyName"
             :store-name="data.data.storeName"
             @account-no-clicked="onFilterByAccountNoLink(data)"
           />
          </template>
          <dx-column data-field="status" cell-template="statusCell" caption="Status Info" :allow-sorting="false" />
          <template #statusCell="{ data }">
            <status-info
              :problem-type="data.data.problemType"
              :problem-status="data.data.problemStatus"
              :damage-type="data.data.damageType"
              :problem-resolution-type="data.data.problemResolutionType"
            />
          </template>
          <!-- <dx-column data-field="itemId" width="90" /> -->
          <dx-column v-if="hasPermission" data-field="locationName" cell-template="locationCell" caption="Location" :width="144" />
          <template #locationCell="{data}">
            <div>
              <location-setter :row="data" @emit-location-updated="reloadGrid" />
            </div>
          </template>
          <dx-column data-field="count" caption="QTY" :width="60" />
          <dx-column v-if="hasPermission" data-field="openedBy" />
          <dx-column data-field="date" :width="100" data-type="date" />
          <dx-column data-field="carrier" cell-template="carrierTemplate" :allow-sorting="false" />
          <template #carrierTemplate="{ data }">
            <carrier-info :carrier="data.data.carrier" :tracking="data.data.tracking" />
          </template>
          <dx-column
            data-field="invoiceId"
            caption="Invoice"
            cell-template="tempInvoice"
            :visible="isServingTenant"
            :width="75"
          />
          <dx-column
              data-field="fileLink"
              caption="Attach"
              alignment="center"
              cell-template="tempFileLink"
              :width="75"
          />
          <template #tempInvoice="{data}">
            <router-link
              v-if="data.value"
              :to="{ name: 'apps-invoice-preview', params: { id: data.value } }"
            >
              <span class="text-capitalize d-block badge badge-pill badge-light-success">
                Invoice
              </span>
            </router-link>
          </template>
          <template #tempFileLink="{data}">
            <div v-if="data.value">
              <p-icon name="feather-link-2" />
            </div>
          </template>
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1 w-25">
                <dx-util-text-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @enter-key="onFilterByAccountNo"
                  @key-down="onFilterByAccountNo"
                  @value-changed="onFilterByAccountNo"
                />
              </div>
              <div class="mr-1 w-25">
                <dx-util-select-box
                  v-model="selectedWarehouse"
                  :data-source="warehouses"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-25">
                <dx-util-select-box
                  id="problemTypeSelectBox"
                  v-model="filters.problemType"
                  :data-source="problemTypes"
                  :drop-down-options="selectBoxAttributes"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-25">
                <dx-util-select-box
                  id="damageTypeSelectBox"
                  v-model="filters.damageType"
                  :data-source="damageTypes"
                  :drop-down-options="selectBoxAttributes"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-25">
                <dx-util-select-box
                  id="problemStatusSelectBox"
                  v-model="filters.problemStatus"
                  :data-source="problemStatuses"
                  :drop-down-options="selectBoxAttributes"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-25">
                <dx-util-select-box
                  id="problemResolutionTypeBox"
                  v-model="filters.problemResType"
                  :data-source="problemResolutionTypes"
                  :drop-down-options="selectBoxAttributes"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
            </div>
          </template>
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <dx-remote-operations :paging="true" :sorting="true" :filtering="true" />
          <dx-state-storing
            :enabled="true"
            type="custom"
            :custom-load="loadDataGridState"
            :custom-save="saveDataGridState"
          />
          <dx-sorting mode="single" />
        </dx-data-grid>
      </div>
    </div>
    <take-action :id="selectedItem.id" :component-id="takeActionComponentId" :detail="selectedItem" :sidebar-hidden-callback="searchByFilter" @hide-take-action="hideTakeAction" />
    <barcode-box :component-id="barcodeBoxComponentId" :barcode="selectedBarcode" :barcode-text="selectedBarcodeText" />
    <div class="col-md-12">
      <!--Begin:: Charge Component-->
      <dx-util-popup
        id="popupCharges"
        ref="popupChargesRef"
        :show-close-button="true"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="true"
        :height="720"
        content-template="popup-content"
        title="Problem Charges"
        @showing="onShowingChargesPopup"
        @hiding="onHidingChargesPopup"
      >
      <dx-util-position at="center" my="center" />
      <template #popup-content>
        <charge-item v-if="charges.isCreated" :item-id="charges.itemId" :visible-default-charge="false" />
      </template>
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveChargesOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="closeChargesOptions" />
    </dx-util-popup>
      <!--End:: Charge Component-->

      <!--Begin:: Print Component-->
      <print :component-id="printerComponentId" :payload="printerPayload" />
      <!--End:: Print Component-->
      <problem-form :component-id="problemFormComponentId" @emit-form-saved="onProblemAdded" />
    </div>
  </div>
</template>
<script>

/* eslint-disable no-unused-vars */
import store from '@/store'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import { ValueToEnum, Notify } from '@robustshell/utils/index'
import { v4 as uuidv4 } from 'uuid'
import isEmpty from 'lodash/isEmpty'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { formatDate } from '@core/utils/filter'
import ProblemFilter from '@/http/models/search-filters/ProblemFilter'
import problemManagementService from '@/http/requests/problem-management/problemManagementService'
import settingsService from '@/http/requests/common/settings'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import { DataGridNamesEnum } from '@/enums'
import useCurrentUser from '@/libs/app/current-user'
import ChargeItem from '@components/charge/TheChargeItem.vue'
import Print from '@components/printer/Print.vue'
import itemChargeService from '@/http/requests/finance/itemChargeService'
import TakeAction from './view/TakeAction.vue'
import { problemManagementSource } from './problemManagemetStore'
import BarcodeBox from './view/BarcodeBox.vue'
import LocationSetter from './components/LocationSetter.vue'
import ProblemItemInfo from './components/ItemInfo'
import StoreInfo from './components/StoreInfo'
import StatusInfo from './components/StatusInfo'
import CarrierInfo from './components/CarrierInfo'

export default {
  components: {
    ChargeItem,
    Print,
    'problem-item-info': ProblemItemInfo,
    'store-info': StoreInfo,
    'status-info': StatusInfo,
    'carrier-info': CarrierInfo,
    'location-setter': LocationSetter,
    'take-action': TakeAction,
    'barcode-box': BarcodeBox,
    'problem-form': () => import('./components/ProblemForm.vue'),
  },
  mixins: [GridBase, Filters],
  setup() {
    const {
      userId,
      userCompanyId,
      isServingTenant,
      isCompanyTypeWarehouse,
    } = useCurrentUser()
    return {
      userId, userCompanyId, isServingTenant, isCompanyTypeWarehouse,
    }
  },
  data() {
    return {
      filters: {
        query: '',
        problemType: 'none',
        damageType: 'none',
        problemStatus: 'none',
        problemResType: 'none',
        filterOn: [],
      },
      selectBoxAttributes: {
        minWidth: '250px',
      },
      accountNo: '',
      isSearchAction: false,
      problemManagementSource,
      selectedItem: { id: 0 },
      barcodeItem: {
        orderNumberBarcode: '',
        size: 20,
      },
      notificationProblemId: null,
      problemStatuses: [],
      problemResolutionTypes: [],
      damageTypes: [],
      problemTypes: [],
      charges: {
        itemId: null,
        isCreated: false,
      },
      barcodeBoxComponentId: '',
      selectedBarcode: 0,
      selectedBarcodeText: '',
      takeActionComponentId: '',
      printerComponentId: '',
      printerPayload: {},
      problemFormComponentId: '',
      dataGridState: {},
      problemDataSource: [],
      currentGridStateId: null,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      const dataGridIns = this.$refs.problemManagementGridRef.instance
      return dataGridIns
    },
    saveChargesOptions() {
      return {
        text: 'Save Charges',
        type: 'success',
        onClick: () => {
          this.onSaveCharges()
        },
      }
    },
    closeChargesOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          const popup = this.$refs.popupChargesRef.instance
          popup.hide()
        },
      }
    },
  },
  created() {
    const query = { ...this.$route.query }
    if (!isEmpty(query)) {
      this.notificationProblemId = query.id
    }
    if (this.isCompanyTypeWarehouse) {
      this.filters.problemStatus = 'waiting_action_from_prep_center'
    }
    if (!this.isCompanyTypeWarehouse && this.isServingTenant) {
      this.filters.problemStatus = 'waiting_action_from_customer'
    }
  },
  mounted() {
    this.getWarehouses()
    this.initialOpenTakeAction()
    this.initialLoad()

    this.problemResolutionTypes.splice(0, this.problemResolutionTypes.length)
    this.problemStatuses.splice(0, this.problemStatuses.length)
    this.damageTypes.splice(0, this.damageTypes.length)
    this.problemTypes.splice(0, this.problemTypes.length)
    Object.keys(this.$enums.ProblemResolutionTypeEnum).map(key => this.problemResolutionTypes.push({ value: this.$enums.ProblemResolutionTypeEnum[key].value, text: this.$enums.ProblemResolutionTypeEnum[key].text }))
    Object.keys(this.$enums.ProblemStatusEnum).map(key => this.problemStatuses.push({ value: this.$enums.ProblemStatusEnum[key].value, text: this.$enums.ProblemStatusEnum[key].text }))
    Object.keys(this.$enums.DamageTypeEnum).map(key => this.damageTypes.push({ value: this.$enums.DamageTypeEnum[key].value, text: this.$enums.DamageTypeEnum[key].text }))
    Object.keys(this.$enums.ProblemTypeEnum).map(key => this.problemTypes.push({ value: this.$enums.ProblemTypeEnum[key].value, text: this.$enums.ProblemTypeEnum[key].text }))
  },
  methods: {
    initialOpenTakeAction() {
      if (this.notificationProblemId) {
        problemManagementService.fetchById(this.notificationProblemId).then(result => {
          this.selectedItem = { ...result.data }
        }).then(() => {
          this.takeActionComponentId = uuidv4()
        })
      }
    },
    hideTakeAction() {
      if (this.notificationProblemId) {
        this.notificationProblemId = null
        this.$router.push({ name: 'problem-management' })
      }
      this.searchByFilter()
    },
    getEnumFormatted(items, val) {
      const enumItem = ValueToEnum(items, val)
      return enumItem.text
    },
    async setActionByItem(item) {
      switch (item.id) {
        case 'resetDatagridState':
          this.resetGridState()
          break
        default:
          break
      }
    },
    async resetGridState() {
      if (this.currentGridStateId === null) {
        Notify.warning('This datagrid does not have any settings to reset.')
        return
      }
      await settingsService.delete(this.currentGridStateId)
      window.location.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Problems',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Problems',
          onItemClick: arg => {
            if (arg.itemData.id === 'all') {
              this.onDownloadProblems()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadSelectedProblems()
            }
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'add',
          type: 'success',
          hint: 'Create a new problem',
          visible: this.hasPermission,
          onClick: () => {
            this.problemFormComponentId = uuidv4()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reloadGrid()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          icon: 'menu',
          showArrowIcon: false,
          items: [
            {
              id: 'resetDatagridState',
              name: 'Reset Datagrid Settings',
              icon: 'icon bi-x-square',
            },
          ],
          width: 36,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Set Preferences',
          dropDownOptions: { width: 'auto' },
          dropDownContentTemplate: 'buttons',
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        this.isSearchAction = true
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    initialLoad() {
      ProblemFilter.setDefaultFilters()
      const filters = ProblemFilter.getFilters()
      filters.problemStatus = this.filters.problemStatus
      problemManagementSource.searchValue(filters)
    },
    searchByFilter() {
      ProblemFilter.accountNo = parseInt(this.accountNo, 10)
      ProblemFilter.warehouseId = this.selectedWarehouse
      ProblemFilter.problemType = this.filters.problemType
      ProblemFilter.damageType = this.filters.damageType
      ProblemFilter.problemStatus = this.filters.problemStatus
      ProblemFilter.resolutionType = this.filters.problemResType
      ProblemFilter.q = this.filters.query
      const filters = ProblemFilter.getFilters()
      problemManagementSource.searchValue(filters)
      problemManagementSource.reload()
    },
    reload() {
      ProblemFilter.setDefaultFilters()
      this.dataGrid.clearFilter()
      const filters = ProblemFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
      this.accountNo = ProblemFilter.accountNo
      this.selectedWarehouse = ProblemFilter.warehouseId
      this.filters.problemType = ProblemFilter.problemType
      this.filters.damageType = ProblemFilter.damageType
      this.filters.problemStatus = ProblemFilter.problemStatus
      this.filters.problemResType = ProblemFilter.resolutionType
      this.filters.query = ProblemFilter.q
    },
    onFilterByAccountNoLink(data) {
      this.accountNo = data.value.toString()
      this.searchByFilter()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.searchByFilter()
      }
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        problemManagementSource.pageIndex(0)
      }
    },
    reloadGrid() {
      this.dataGrid.option('dataSource', problemManagementSource)
    },
    onTakeAction(e) {
      this.selectedItem = { ...e.row.data }
      this.takeActionComponentId = uuidv4()
    },
    handleSubmit(e) {
      const form = this.$refs.assignLocationFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const params = {
          id: this.assignLocationFormData.id,
          location: this.assignLocationFormData.location.trim(),
        }
        problemManagementService.assignLocation(params).then(result => {
          if (result.status === 200) {
            this.assignLocationPopup.hide()
            this.reloadGrid()
            // update table location or reload data
          }
        })
      }
    },
    isActiveChargeAction(e) {
      return e.row.data.invoiceId && e.row.data.invoiceId !== null
    },
    onClickAddCharges(e) {
      const { data } = e.row
      const popup = this.$refs.popupChargesRef.instance
      this.charges.itemId = data.id
      popup.show()
    },
    onShowingChargesPopup() {
      this.charges.isCreated = true
    },
    onHidingChargesPopup() {
      this.charges = {
        itemId: null,
        isCreated: false,
      }
    },
    onSaveCharges() {
      store.dispatch('charge/setCharges', this.charges.itemId)
      const chargeItems = store.getters['charge/getCharges']
      const getChargeErrors = store.getters['charge/getChargeErrors']
      if (getChargeErrors.length > 0) {
        Notify.danger(getChargeErrors.join(','))
      } else {
        const charges = [...chargeItems]
        itemChargeService.create(this.charges.itemId, charges)
          .then(() => {
            store.dispatch('charge/clearCharges')
          })
          .then(() => {
            const popup = this.$refs.popupChargesRef.instance
            popup.hide()
          })
      }
    },
    async onDownloadProblems(e) {
      const filters = ProblemFilter.getFilters()
      const pageableQuery = 'page=0&size=20000'
      const response = await problemManagementService.fetchAllByQuery(filters, pageableQuery)
      const data = response.data.content
      this.onDownloadExportData(data)
      // const response = await problemManagementService.getListToDownload(filters, pageableQuery)
      // const blob = new Blob([response], { type: 'text/csv' })
      // const url = URL.createObjectURL(blob)

      // const a = document.createElement('a')
      // a.href = url
      // a.download = 'problems.csv'
      // a.click()

      // URL.revokeObjectURL(url)
    },
    onDownloadSelectedProblems() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Inventory ${fileDate}`)
      worksheet.columns = [
        { header: 'Barcode', key: 'id', width: 20 },
        { header: 'Title', key: 'title', width: 100 },
        { header: 'Order Number', key: 'orderNumber', width: 20 },
        { header: 'UPC', key: 'upc', width: 14 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'Supplier', key: 'supplier', width: 30 },
        { header: 'Account No', key: 'accountNo', width: 30 },
        { header: 'Suite No', key: 'suiteNo', width: 30 },
        { header: 'Company Name', key: 'companyName', width: 30 },
        { header: 'Store Name', key: 'storeName', width: 30 },
        { header: 'Location Name', key: 'locationName', width: 30 },
        { header: 'Problem Type', key: 'problemType', width: 30 },
        { header: 'Damage Type', key: 'damageType', width: 30 },
        { header: 'Status', key: 'problemStatus', width: 30 },
        { header: 'Resolve Type', key: 'problemResolutionType', width: 30 },
        { header: 'Quantity', key: 'count', width: 30 },
        { header: 'Opened By', key: 'openedBy', width: 30 },
        { header: 'Date', key: 'date', width: 30 },
        { header: 'Carrier', key: 'carrier', width: 30 },
        { header: 'Tracking', key: 'tracking', width: 30 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:N1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.id,
          item.title,
          item.orderNumber,
          item.upc,
          item.asin,
          item.supplier,
          item.accountNo,
          item.suiteNo,
          item.companyName,
          item.storeName,
          item.locationName,
          item.problemType,
          item.damageType,
          item.status,
          item.problemResolutionType,
          item.count,
          item.openedBy,
          formatDate(item.date),
          item.carrier,
          item.tracking,
          item.invoiceId,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Problems ${fileDate}.xlsx`,
        )
      })
    },
    // Print Events
    printOrderNumberBarcode(data, type) {
      const title = type ? `${type} #: ${data}` : data
      this.printerPayload = {
        barcode: data,
        title: title,
        expressionDate: null,
      }
      this.$nextTick(() => {
        this.printerComponentId = uuidv4()
      })
    },
    onProblemAdded() {
      this.reloadGrid()
    },
    async loadDataGridState(e) {
      const dataGridKey = DataGridNamesEnum.PROBLEMS_GRID.key
      const result = await settingsService.getByReference(referenceTypeEnum.USER.value, this.userId, dataGridKey)
      this.currentGridStateId = result.data.id
      this.dataGridState = {}
      if (result.data.value) {
        const state = JSON.parse(result.data.value)
        state.searchText = ''
        if (state.pageIndex) {
          delete state.pageIndex
        }
        this.dataGridState = state
      }
      return this.dataGridState
    },
    async saveDataGridState(e) {
      if (e === undefined || e === null) {
        return
      }
      if (JSON.stringify(e) === JSON.stringify(this.dataGridState)) {
        return
      }
      if (e.searchText !== '' || this.isSearchAction) {
        this.isSearchAction = false
        return
      }
      const dataGridPreferences = { ...e }
      dataGridPreferences.selectedRowKeys = []
      dataGridPreferences.searchText = ''
      const dataGridKey = DataGridNamesEnum.PROBLEMS_GRID.key
      const preferenceData = {
        companyId: this.userCompanyId,
        referenceId: this.userId,
        referenceType: referenceTypeEnum.USER.value,
        settingType: SettingsTypeEnum.DATAGRID_STATES.value,
        settingKey: dataGridKey,
        description: `State of the ${dataGridKey} for UserId: ${this.userId}`,
        settingName: dataGridKey,
        value: JSON.stringify(dataGridPreferences),
      }
      const result = await settingsService.createOrUpdate(preferenceData)
      this.currentGridStateId = result.data.body.id
    },
  },
}
</script>

<style lang="scss">
</style>
