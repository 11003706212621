<template>
  <dx-util-popup
    ref="barcodeBoxPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    width="440px"
    height="auto"
    :title="barcodeBoxTitle"
    :full-screen="false"
    @shown="onShownBarcodeBox"
  >
    <div id="printJS-barcode" class="d-block text-center">
      <barcode :value="barcode" :options="{ displayValue: true, text: barcodeText, fontSize: 20 }" />
    </div>
    <div class="text-center px-2 py-1">
      <dx-util-button text="Print Barcode" type="default" class="d-block" @click="printBarcode" />
    </div>
  </dx-util-popup>
</template>

<script>
import PrintJS from 'print-js'

export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
    barcode: {
      type: Number,
      required: true,
    },
    barcodeText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      barcodeBoxTitle: '',
    }
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.$refs.barcodeBoxPopupRef.instance.show()
      },
    },
  },
  methods: {
    onShownBarcodeBox() {
      this.barcodeBoxTitle = 'Print Barcode'
    },
    printBarcode() {
      PrintJS('printJS-barcode', 'html')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
