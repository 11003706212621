<template>
  <div class="w-100">
    <div class="d-flex flex-row">
      <div style="margin-right: 0.625rem" class="align-self-start">
        <light-box :items="[largeImage]" :index="index" :effect="'fade'" @close="index = null" />
        <div class="cursor-pointer" @click="showImage">
          <p-lazy-img width="75px" height="75px" :src="smallImage || defaultImg" />
        </div>
      </div>
      <div class="d-flex flex-column w-100">
        <div :class="showProductDetails ? 'text-info' : 'text-warning'" class="title-container d-inline-block text-truncate" style="font-size: 14.5px; font-weight: 700;">
          <span v-if="!isTruncated" @click="triggerOpenProductDetails">
            {{ title }}
          </span>
          <span v-else :id="`item-info-${id}`" class="d-inline-block text-truncate" :style="{ maxWidth: `${width * 0.75}px`}" @click="triggerOpenProductDetails">
            {{ title }}
            <dx-util-popover :width="300" :visible="false"
              :target="`#item-info-${id}`" position="bottom"
              show-event="dxhoverstart"
              hide-event="dxhoverend"
            >
            {{ title }}
            </dx-util-popover>
          </span>
        </div>

        <div class="d-flex flex-row justify-content-between">
          <div class="col px-0">
            <div>
              <copy-button :copy-value="asin" class="m-0" />
              ASIN:
              <a :href="`https://www.amazon.com/gp/product/${asin}`" target="_blank" title="Show on Amazon">
                {{ asin }}
              </a>
            </div>
            <div>
              <copy-button :copy-value="upc" class="m-0" />
              UPC:
              <a target="_blank" :href="`https://www.barcodelookup.com/${upc}`">
                {{ upc }}
              </a>
            </div>
            <div :id="`msku-${msku}`">
              <copy-button :copy-value="msku" class="m-0" />
              <span class="d-inline-block text-ellipsis" :style="{ maxWidth: `${width * 0.35}px`}">
                MSKU: {{ msku }}
              </span>
              <dx-util-popover :width="300" :visible="false"
                :target="`#msku-${msku}`" position="bottom"
                show-event="dxhoverstart"
                hide-event="dxhoverend"
              >
              {{ msku }}
              </dx-util-popover>

            </div>
            <slot name="left" />
          </div>
          <div class="col px-0">
            <div>
              <copy-button :copy-value="msku" class="m-0" />
              Supplier: {{ supplier }}
            </div>
            <div>
              <copy-button :copy-value="id" class="m-0" />
              Barcode: {{ id }}
            </div>
            <div>
              <copy-button :copy-value="orderId" class="m-0" />
              Order No: {{ orderId }}
            </div>
            <slot name="right" />
          </div>
        </div>
        <div class="d-flex flex-row justify-content-end">
          <slot />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'light-box': CoolLightBox,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    asin: {
      type: String,
      default: '',
    },
    upc: {
      type: String,
      default: '',
    },
    msku: {
      type: String,
      default: '',
    },
    supplier: {
      type: String,
      default: '',
    },
    orderId: {
      type: String,
      default: '',
    },
    productId: {
      type: Number,
      default: 0,
    },
    smallImage: {
      type: String,
      default: '',
    },
    largeImage: {
      type: String,
      default: '',
    },
    isTruncated: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 350,
    },
    showProductDetails: {
      type: Boolean,
      default: false,
    },
    shrink: { // Whether hide the info type if they empty. If false, empty info shown with dash.
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultImg: defaultImageUrl,
      images: [],
      index: null,
    }
  },
  mounted() {
    // this.images = [this.item.largeImage]
  },
  methods: {
    showImage() {
      this.index = 0
    },
    triggerOpenProductDetails() {
      if (this.showProductDetails) {
        this.$emit('open-product-details', this.productId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title-container {
 word-wrap: break-word;
 overflow-wrap: break-word;
 white-space: normal;
 max-width: 100%;
}

.text-ellipsis {
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 padding-left: 4px;
}

</style>
