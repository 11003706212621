<template>
  <dx-util-popup
    ref="takeActionPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="takeActionTitle"
    :full-screen="true"
    @shown="onShownTakeAction"
    @hidden="onHiddenTakeAction"
  >
  <dx-util-scroll-view height="100%" width="100%">
    <div class="row flex-wrap align-items-start flex-wrap">
      <div class="col-12 col-md-6">
        <div v-if="!isResolved" class="card border h-100">
          <div class="d-flex card-header bg-light-primary p-1">
            <div class="d-flex align-items-center mr-1 border-0">
              <div class="px-1">
                <p-icon name="feather-alert-triangle" size="48px" color="primary" />
              </div>
              <div class="card-title">
                <h4 class="m-0">
                  Problem Actions
                </h4>
                <p style="font-size:14px;" class="m-0">
                  Update the problem details of #{{ id }} <p-icon name="feather-copy" size="14px" role="button" @click.native="onCopy(id)" />
                </p>
              </div>
            </div>
            <div v-if="hasPermission" class="text-center">
              <div>
                <small>LOCATION</small>
              </div>
              <div class="text-warning badge badge-light-warning" style="min-width: 80px;font-size: 14px;">
                {{ detail.locationName || 'N/A' }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <dx-util-validation-group :ref="validationGroupRef">
              <dx-util-select-box
                v-model="formData.problemResolutionType"
                class="m-1 mx-half"
                label="Action"
                :data-source="problemResolutionTypes"
                display-expr="text"
                value-expr="value"
                @value-changed="onChangeProblemResolutionType"
              >
                <dx-util-validator>
                  <dx-util-required-rule message="Action is required" />
                </dx-util-validator>
              </dx-util-select-box>
              <dx-util-select-box
                v-if="formData.problemResolutionType === 'changed_item_action'"
                v-model="formData.itemAction"
                class="m-1 mx-half"
                label="Action"
                :data-source="itemActionList"
                display-expr="text"
                value-expr="value"
              >
              <dx-util-validator>
                  <dx-util-required-rule message="Item action is required" />
                </dx-util-validator>
              </dx-util-select-box>
              <dx-util-text-box
                v-if="formData.problemResolutionType === 'use_alternative_asin'"
                v-model="existingAsin"
                label="Existing ASIN" class="m-1 mx-half"
                :read-only="true"
              />
              <dx-util-text-box
                v-if="formData.problemResolutionType === 'use_alternative_asin'"
                v-model="newAsin"
                placeholder="Please enter new ASIN"
                label="New ASIN" class="m-1 mx-half"
              >
                <dx-util-validator>
                  <dx-util-required-rule message="New ASIN is required" />
                </dx-util-validator>
              </dx-util-text-box>
              <dx-util-text-area v-model="formData.notes"
                placeholder="Please enter an explantion for the action"
                label="Notes" class="m-1 mx-half"
                height="100px"
              >
                <dx-util-validator>
                  <dx-util-required-rule message="Notes is required" />
                </dx-util-validator>
              </dx-util-text-area>
              <div v-if="formData.problemResolutionType === 'return_to_customer' || formData.problemResolutionType === 'return_to_supplier'"
                class="border rounded p-1 mx-half"
              >
                <div class="d-flex pb-1">
                  <dx-util-check-box
                    v-model="weWillProvideShippingLabel"
                    text="We will provide shipping label"
                    class="col-md-6"
                  />
                  <dx-util-check-box
                    v-model="dpcWillProvideShippingLabel"
                    text="Prep center will provide shipping label"
                    class="col-md-6"
                  />
                </div>
                <dx-util-text-box v-model="tracking" class="m-1 mx-half" label="Tracking Number">
                  <dx-util-validator>
                    <dx-util-required-rule v-if="weWillProvideShippingLabel" message="Tracking number is required" />
                  </dx-util-validator>
                </dx-util-text-box>
                <dx-util-text-box v-model="carrier" class="m-1 mx-half" label="Carrier">
                  <dx-util-validator>
                    <dx-util-required-rule v-if="weWillProvideShippingLabel" message="Carrier is required" />
                  </dx-util-validator>
                </dx-util-text-box>
              </div>
            </dx-util-validation-group>
            <charge-item v-if="showCostComponent" :item-id="formData.id" class="mx-half" />
          </div>
          <div class="text-right p-1">
            <dx-util-button type="danger" text="Close" class="mr-1" style="min-width:100px" @click="onClose" />
            <dx-util-button type="success" text="Save" style="min-width:100px" @click="onSubmit" />
          </div>
        </div>
        <div class="col-12 px-0 mx-0">
          <service-folder-file-manager
            v-if="formData && formData.companyId"
            ref="refServiceFolderFileManager"
            class="px-0 mx-0"
            card-subtitle="Upload files related to the problem"
            :company-id="formData.companyId"
            :title="titleComputed"
            :upload-document-classes="tabs"
            :entity-folder-id="entityFolderId"
            :upload-document-class="activeClass"
            :service-folder="serviceFolder"
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <problem-history :problem-id="id" :detail="problemHistory" @emit-close-take-action="onClose">
          <div>
            <dx-util-button
              text="Re-open Case" type="danger"
              @click="reopenCase"
            />
          </div>
        </problem-history>
      </div>
    </div>
    </dx-util-scroll-view>
  </dx-util-popup>
</template>

<script>
import problemManagementService from '@/http/requests/problem-management/problemManagementService'
import { Notify } from '@robustshell/utils/index'
import ChargeItem from '@components/charge/TheChargeItem.vue'
import { formatDate } from '@core/utils/filter'
import store from '@/store'
import chargeStoreModule from '@components/charge//chargeStoreModule'
import ServiceFolderFileManager from '@components/service-folder/ServiceFolderFileManager.vue'
import { getUserData } from '@/auth/utils'
import { getItemActionEnumList } from '@/enums/item-action.enum'
import problemResolutionTypeEnum from '@/enums/problemResolutionTypeEnum'
import problemStatusEnum from '@/enums/problemStatusEnum'
import ProblemHistory from './ProblemHistory.vue'

const CHARGE_STORE_MODULE = 'charge'
if (!store.hasModule(CHARGE_STORE_MODULE)) store.registerModule(CHARGE_STORE_MODULE, chargeStoreModule)

export default {
  components: {
    ChargeItem,
    ProblemHistory,
    'service-folder-file-manager': ServiceFolderFileManager,
    // Form Validation
  },
  props: {
    id: {
      type: Number || null,
      default: null,
    },
    componentId: {
      type: String,
      default: '',
    },
    sidebarHiddenCallback: {
      required: false,
      type: Function,
      default: () => { },
    },
    detail: {
      type: Object,
      default: null,
    },
  },
  emits: ['hide-take-action'],
  data: () => ({
    takeActionTitle: '',
    validationGroupRef: 'takeActionFormRef',
    itemActionList: getItemActionEnumList()?.filter(el => el.value !== 'ALL'),
    errors: {},
    companyType: null,
    weWillProvideShippingLabel: false,
    dpcWillProvideShippingLabel: false,
    serviceFolder: 'PROBLEMS',
    tabs: ['Photo', 'ShippingLabel'],
    activeClass: 'Photo',
    isBusy: false,
    problemDetails: {
      openedBy: '',
      history: {
        problem: {
          damageType: '',
          date: '',
          notes: '',
          problemType: '',
          resolutionType: '',
          status: '',
        },
        actions: [],
      },
    },
    formData: {
      id: '',
      approvedAt: '',
      approvedBy: '',
      carrier: '',
      companyId: '',
      count: '',
      damageType: '',
      fileLink: '',
      itemId: '',
      notes: '',
      itemAction: '',
      openedBy: '',
      prepItemCostId: '',
      problemResolutionType: '',
      problemStatus: '',
      problemType: '',
      resolvedAt: '',
      resolvedBy: '',
      tracking: '',
      warehouseId: '',
      warehouseLocation: '',
    },
    costs: [],
    existingAsin: '',
    newAsin: '',
    carrier: '',
    tracking: '',
    problemResolutionType: '',
    problemResolutionTypes: [],
    options: {
      companiesForCombobox: [],
      itemsForCombobox: [],
      warehouseLocationForCombobox: [],
    },
    // cost data
    showCostComponent: false,
    problemHistory: {},
    problemResolutionTypeEnum,
  }),
  computed: {
    pageTitle() {
      return this.problemResolutionType === 'resolved' ? `Action History - Barcode: ${this.id}` : `Take Action - Barcode: ${this.id}`
    },
    isResolved() {
      return this.problemResolutionType === 'resolved'
    },
    titleComputed() {
      return `Barcode:${this.id} Images`
    },
    entityFolderId() {
      return this.id
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.$refs.takeActionPopupRef.instance.show()
        this.getProblemData()
      },
    },
    weWillProvideShippingLabel(currentValue) {
      if (currentValue) {
        this.dpcWillProvideShippingLabel = !currentValue
      }
      if (currentValue === true) {
        if (!this.formData.notes.includes('We  will provide shipping label')) {
          // eslint-disable-next-line operator-assignment
          this.formData.notes += '\n We  will provide shipping label'
        }
      } else {
        // eslint-disable-next-line operator-assignment,no-lonely-if
        if (this.formData.notes.includes('We  will provide shipping label')) {
          this.formData.notes = this.formData.notes.replaceAll('\n We  will provide shipping label', '')
        }
      }
    },
    dpcWillProvideShippingLabel(currentValue) {
      if (currentValue) {
        this.weWillProvideShippingLabel = !currentValue
      }
      if (currentValue === true) {
        if (!this.formData.notes.includes('Prep center will provide shipping label')) {
          // eslint-disable-next-line operator-assignment
          this.formData.notes += '\n Prep center will provide shipping label'
        }
      } else {
        // eslint-disable-next-line operator-assignment,no-lonely-if
        if (this.formData.notes.includes('Prep center will provide shipping label')) {
          this.formData.notes = this.formData.notes.replaceAll('\n Prep center will provide shipping label', '')
        }
      }
    },
  },
  mounted() {
    this.setDisabledProblemResolationTypes()
    this.loadCompanyType()
  },
  methods: {
    formatedDate(date) {
      return formatDate(date)
    },
    async onCopy(text) {
      await navigator.clipboard.writeText(text)
    },
    setDisabledProblemResolationTypes() {
      const isDisabled = !(this.$can('read', 'resource_customer_account_no'))
      const data = problemResolutionTypeEnum
      data.resolved.disabled = isDisabled
      data.restock_to_inbound.disabled = isDisabled
      this.problemResolutionTypes = Object.values(data)
    },
    onClose() {
      this.$refs.takeActionPopupRef.instance.hide()
      this.showCostComponent = false
    },
    onHiddenTakeAction() {
      this.carrier = ''
      this.tracking = ''
      this.existingAsin = ''
      this.newAsin = ''
      this.showCostComponent = false
      this.sidebarHiddenCallback()
      this.$emit('hide-take-action')
    },
    onShownTakeAction() {
      this.takeActionTitle = this.pageTitle
      this.formData.problemResolutionType = ''
      this.$refs.takeActionFormRef.instance.reset()
    },
    getProblemData() {
      problemManagementService.fetchById(this.id).then(result => {
        const item = result.data
        this.formData.id = item.id
        this.formData.approvedAt = item.approvedAt
        this.formData.approvedBy = item.approvedBy
        this.formData.carrier = item.carrier
        this.formData.companyId = item.companyId
        this.formData.count = item.count
        this.formData.damageType = item.damageType
        this.formData.fileLink = item.fileLink
        this.formData.itemId = item.itemId
        this.formData.notes = ''
        this.formData.itemAction = item.itemAction
        this.formData.openedBy = item.openedBy
        this.formData.prepItemCostId = item.prepItemCostId
        this.formData.problemResolutionType = item.problemResolutionType
        this.formData.problemStatus = item.problemStatus
        this.formData.problemType = item.problemType
        this.formData.resolvedAt = item.resolvedAt
        this.formData.resolvedBy = item.resolvedBy
        this.formData.tracking = item.tracking
        this.formData.warehouseId = item.warehouseId
        this.formData.warehouseLocation = item.warehouseLocation

        this.carrier = item.carrier
        this.tracking = item.tracking
        this.existingAsin = this.detail.asin
        this.newAsin = ''
        this.problemResolutionType = item.problemResolutionType

        const actions = JSON.parse(item.notes)
        this.problemDetails.history.problem = {
          damageType: actions.damageType,
          date: actions.date,
          notes: actions.notes,
          problemType: actions.problemType,
          resolutionType: actions.resolutionType,
          status: actions.status,
        }
        this.problemDetails.history.actions = actions.actions
        this.problemDetails.openedBy = this.detail.openedBy
      })
        .finally(() => {
          this.problemHistory = JSON.parse(JSON.stringify(this.problemDetails))
          if (this.formData.problemResolutionType === 'resolved' || this.formData.problemResolutionType === 'return_to_customer' || this.formData.problemResolutionType === 'return_to_supplier') {
            this.weWillProvideShippingLabel = true
            this.dpcWillProvideShippingLabel = false
          } else {
            this.weWillProvideShippingLabel = false
            this.dpcWillProvideShippingLabel = false
          }
        })
    },
    onChangeProblemResolutionType(e) {
      const type = e.value
      if (type === 'resolved') {
        this.showCostComponent = true
      } else if (type === 'use_alternative_asin') {
        this.weWillProvideShippingLabel = false
        this.dpcWillProvideShippingLabel = false
        this.showCostComponent = false
      } else {
        this.showCostComponent = false
      }
    },
    async onSubmit() {
      const validationResult = this.$refs.takeActionFormRef.instance.validate()

      if (validationResult.isValid) {
        // TODO: there is no isNew, remove if it is not necessary.
        if (this.isNew) {
          // eslint-disable-next-line no-unused-vars
          problemManagementService.create(this.formData).then(result => {})
        } else {
          if (this.weWillProvideShippingLabel && this.companyType && this.companyType === 'customer') {
            if (this.$refs.refServiceFolderFileManager.shippingLabelCount === 0) {
              Notify.error('Please upload shipping label')
              return
            }
          }
          if (this.formData.problemResolutionType === 'none' || this.formData.problemResolutionType === 'not_resolved_yet') {
            Notify.warning('Please select an Action.')
          }
          const formData = this.formData
          const newNote = this.createNewNote(formData)

          store.dispatch('charge/setCharges', formData.id)
          const chargeItems = store.getters['charge/getCharges']
          const getChargeErrors = store.getters['charge/getChargeErrors']
          if (getChargeErrors.length > 0) {
            Notify.danger(getChargeErrors.join(','))
          } else {
            store.dispatch('charge/clearCharges')
            const action = {
              id: formData.id,
              resolutionType: formData.problemResolutionType,
              itemAction: formData.itemAction,
              count: formData.count,
              asin: this.newAsin,
              carrier: this.carrier,
              tracking: this.tracking,
              notes: JSON.stringify(newNote),
              costs: chargeItems,
            }
            problemManagementService.takeAction(action).then(() => {
              this.showCostComponent = false
              this.onClose()
            })
          }
        }
      } else {
        const err = []
        Object.keys(this.errors).map(key => err.push(this.errors[key]))
        Notify.warning(`Please make sure that you enter all necessary information.${err.join('\n')}`)
      }
    },
    createNewNote(formData) {
      const currentUser = JSON.parse(localStorage.getItem('userData'))

      const newAction = {
        resolutionType: formData.problemResolutionType,
        notes: formData.notes,
        actionTakenBy: currentUser.fullname,
        date: new Date(),
      }

      if (newAction.resolutionType === 'use_alternative_asin') {
        newAction.notes = `${newAction.notes} Old ASIN: ${this.existingAsin}; New ASIN: ${this.newAsin}`
      }

      const actions = this.problemDetails.history.actions
      actions.push(newAction)
      const newNote = {
        damageType: this.problemDetails.history.problem.damageType,
        date: this.problemDetails.history.problem.date,
        notes: this.problemDetails.history.problem.notes,
        problemType: this.problemDetails.history.problem.problemType,
        resolutionType: this.problemDetails.history.problem.resolutionType,
        status: this.problemDetails.history.problem.status,
        actions: actions,
      }
      return newNote
    },
    loadCompanyType() {
      this.companyType = getUserData().companyType
    },
    async reopenCase() {
      // Firstly fill newAction section of createNewNote
      this.formData.problemResolutionType = problemResolutionTypeEnum.notResolvedYet.value
      this.formData.notes = 'This problem is re-opened.'

      // Secondly fill newNote of createNewNote
      // Only update fields 1-resolutionType 2-status
      this.problemDetails.history.problem.resolutionType = problemResolutionTypeEnum.notResolvedYet.value
      this.problemDetails.history.problem.status = problemStatusEnum.waitingActionFromPrepCenter.value

      const formData = this.formData
      const newNote = this.createNewNote(formData)

      const action = {
        id: formData.id,
        resolutionType: formData.problemResolutionType,
        count: formData.count,
        asin: '',
        carrier: '',
        tracking: '',
        costs: [],
        notes: JSON.stringify(newNote),
      }
      problemManagementService.takeAction(action).then(() => {
        problemManagementService.update({
          id: formData.id,
          status: problemStatusEnum.waitingActionFromPrepCenter.value,
        })
        this.onClose()
      })
    },

  },
}
</script>
